import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../components/navbar/Navbar";
import Modal from "../components/modal/Modal";
import Footer from "../components/footer/Footer";
import FirstSection from "../components/firstSection/FirstSection";
// import CenteredNavbar from "../components/centeredNavbar/CenteredNavbar";
import SecondSection from "../components/secondSection/SecondSection";
import ThirdSection from "../components/thirdSection/ThirdSection";
import FourthSection from "../components/fourthSection/FourthSection";
import UseCaseSection from "../components/useCaseSection/UseCaseSection";
import FifthSection from "../components/fifthSection/FifthSection";
import SixthSection from "../components/sixthSection/SixthSection";
import MakeYourMoney from "../components/makeYourMoney/MakeYourMoney";
import NinthSection from "../components/ninthSection/NinthSection";
import LazyIntercom from "../utils/intercom";
import mobileHeroImg from "../assets/images/HeroImageMobile_mu5bem.webp";
import desktopHeroImg from "../assets/images/HeroImage-Web_2x_ac2dz3.webp";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <Helmet>
        <link rel="canonical" href="https://usecrane.co" />
        <link rel="preload" href={mobileHeroImg} as="image" media="(max-width: 768px)" />
        <link rel="preload" href={desktopHeroImg} as="image" />
        <link rel="preload" as="image" href="/static/media/HeroImageMobile_mu5bem.884b28f12e8623df4297.webp" />

        <title>
          Best App to Receive Payments Globally in your local currency | Crane
          App
        </title>

        <meta
          name="title"
          content="Best App to Receive Payments Globally in your local currency | Crane
          App"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="Best App to Receive Payments Globally in your local currency | Crane App"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Enjoy easy global transactions and effortlessly receive payments worldwide in Naira, Shillings and Cedis in 3 steps using Crane App"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="Enjoy easy global transactions and effortlessly receive payments worldwide in Naira, Shillings and Cedis in 3 steps using Crane App"
          data-react-helmet="true"
        />
      </Helmet>

 

      <Navbar bgColor="[#BFFBE2]" textColor="[#050B05]" openModal={openModal} />
      <Modal onClose={closeModal} isOpen={isOpen} />

      <FirstSection
        mobileHeroImg={mobileHeroImg}
        desktopHeroImg={desktopHeroImg}
      />
      {/* <CenteredNavbar /> */}
      <div>
        <SecondSection />
        <ThirdSection />
        <FourthSection />
        <UseCaseSection />
        <FifthSection />
        <SixthSection />
        <MakeYourMoney />
        <NinthSection />
      </div>
      <LazyIntercom />
      <Footer />
    </div>
  );
};

export default Home;
