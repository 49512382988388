import { LazyLoadImage } from "react-lazy-load-image-component";
import QrCodeAndDownloadButtons from "../qrCodeAndDownloadButtons/QrCodeAndDownloadButtons";

const FirstSection = () => {
  const className =
    "flex lg:items-start lg:justify-start items-center justify-center gap-[24px] xl:gap-[15px]";
  return (
    <div className="pb-[102px] md:pb-[140px]">
      <div className="bg-[#FFF] justify-between items-center lg:flex ">
        <div className=" flex justify-center 2xl:justify-start lg:w-1/2 mx-auto lg:ml-[5%] xl:ml-[5%]">
          <div className="flex-col px-8 self-center justify-around gap-10 lg:pb-[130px] pb-[62px]">
            <div className=" flex flex-row justify-center items-center gap-2 pt-[3.5rem] lg:pt-[7rem] lg:justify-start lg:text-left">
              <div className=" lg:w-[16px] lg:h-[16px] w-[12px] h-[12px]">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014489/Check_oyamc9.svg"
                  alt="check-icon"
                  title="check-icon"
                  className="w-full h-full"
                />
              </div>

              <div className="flex flex-col text-left justify-start text-[#525155] text-xs lg:text-base">
                Auto Conversion
              </div>
            </div>

            <h1 className="text-[#050B05] max-w-[670px] capitalize font-extrabold text-[1.75rem] lg:text-[3rem] xl:text-[4rem] leading-tight pt-6 text-center lg:text-left pb-[24px] xl:pb-[40px]">
              Never miss out on the perfect selling opportunity
            </h1>

            <div className="text-[#050B05] max-w-[722px] font-medium text-sm lg:text-xl pb-6 lg:pt-10 pb-[40px] lg:pb-[64px] text-center lg:text-left leading-relaxed">
              <span className="font-bold">Crane’s Auto-convert allows</span> you
              to focus on what really matters and leave payments to us. All you
              have to do is{" "}
              <span className="font-bold">set a specific market value</span> for
              your crypto, and{" "}
              <span className="font-bold">Crane automatically converts </span>
              when that value is reached,{" "}
              <span className="font-bold">
                locking in your desired profit
              </span>{" "}
              without constant monitoring.
            </div>

            <QrCodeAndDownloadButtons className={className} />
          </div>
        </div>

        <div className="lg:h-[600px] sm:h-[450px] md:h-[500px] h-[300px] w-[90%] md:w-[80%] mx-auto lg:w-[680px]  lg:mr-[5%] xl:mr-[5%]">
          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1711533415/Auto_Convert_Illustration_2x_gqowtt.png"
            alt="auto convert illustration"
            title="auto convert illustration"
            className=" w-full h-full "
          />
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
