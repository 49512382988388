import TypingText from "../typingText/TypingText";
import QrCodeAndDownloadButtons from "../qrCodeAndDownloadButtons/QrCodeAndDownloadButtons";
import { LazyLoadImage } from "react-lazy-load-image-component";

const FirstSection = ({ mobileHeroImg, desktopHeroImg }) => {
  const sentences = ["NAIRA", "CEDIS", "SHILLINGS"];
  const className = "flex items-start justify-start gap-[24px] xl:gap-[15px]";

  return (
    <div className="bg-[#BFFBE2] justify-between lg:flex ">
      <div className="justify-center lg:w-1/2  lg:ml-[5%] xl:ml-[5%]">
        <div className="flex-col px-8 self-center justify-around gap-10 lg:pb-[130px] pb-[62px]">
          <div className=" flex flex-row justify-start items-center gap-2 pt-12  lg:text-left">
            <div className=" lg:w-[16px] lg:h-[16px] w-[12px] h-[12px]">
              <LazyLoadImage
                src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014489/Check_oyamc9.svg"
                alt="check-icon"
                title="check-icon"
                className="w-full h-full"
              />
            </div>

            <div className="flex flex-col text-left justify-start text-[#525155] text-xs lg:text-base">
              Quick and easy
            </div>
          </div>
          {/* Desktop */}
          <div className="text-[#050B05] h-[110px]  xl:h-[305px] xl:max-h-[310px] max-w-[670px] capitalize font-extrabold text-[1.75rem] lg:text-[4.5rem] xl:text-[4rem] leading-tight pt-6 text-left">
            <h1 className="typing-text-container">
              <span>Receive global payments locally in </span>
              <span className="typing-text-content">
                <TypingText
                  sentences={sentences}
                  speed={100}
                  sentenceDelay={2000}
                />
              </span>
            </h1>
          </div>
          {/* Mobile */}

          <div className="text-[#050B05] max-w-[722px] font-medium text-sm lg:text-xl pt-6 lg:pt-10 pb-[40px] lg:pb-[64px] text-left leading-relaxed">
            Cash-out crypto to your local currency, We make it easy for you to
            receive money in fiat directly into your local bank account and pay
            for goods and services.
          </div>

          <QrCodeAndDownloadButtons className={className} />
        </div>
      </div>

      <div className=" hidden lg:block w-[552px] lg:w-[800px] xl:w-[779px] h-[912px] relative overflow-hidden">
        <img
          src={desktopHeroImg}
          alt="hero-img"
          title="hero-img"
          className="w-full h-full absolute inset-0 top-0 bottom-0 right-0 object-cover object-center"
          fetchpriority="high"
        />
      </div>
      <div className="lg:hidden block w-full h-full">
        <img
          src={mobileHeroImg}
          alt="hero-img"
          className="w-full h-full mx-auto"
          loading="eager"
          fetchpriority="high"
        />
      </div>
    </div>
  );
};

export default FirstSection;
