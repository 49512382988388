import { useState } from "react";
import Accordion from "../Accordion";
import { allFaqs } from "../../constants";
import { Link } from "react-router-dom";

const SixthSection = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <div className="bg-[#050B05]">
      <div className=" lg:m-auto mx-4 justify-between items-start gap-6 lg:gap-20 flex flex-col lg:flex-row py-24 lg:py-44 lg:w-3/4 xl:w-11/12 ">
        <div className="flex-col px-10 lg-px-8 text-white w-full lg:w-1/2">
          <h2 className=" font-semibold lg:font-medium text-xl lg:text-5xl leading-loose text-center lg:text-left">
            Important things you may want to know
          </h2>
          <div className="font-medium text-sm lg:text-xl pt-6 lg:pt-10  text-center lg:text-left leading-loose">
            We answered some of the questions we think you may want to
            ask.&nbsp;
            <Link
              to="/faqs"
              onClick={() => {
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 100);
              }}
              className="text-[#1AD05D] font-bold"
            >
              Check out our full FAQ page
            </Link>
            &nbsp;to see answers to other questions you may have.
          </div>
        </div>

        {/* ACCORDION */}
        <div className=" w-full lg:w-1/2">
          {allFaqs?.map((item, index) => (
            <Accordion
              key={index}
              title={item.title}
              content={item.content}
              index={index}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SixthSection;
