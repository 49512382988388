import { motion } from "framer-motion";
import { staggerContainer } from "../../utils/motion";
import LottieAnimation from "../LottieAnimation";
// import clock from "../../assets/lottie/AutosellLottie.json";
import globe from "../../assets/lottie/PartyGlobe.json";
import gradient from "../../assets/lottie/GradientColor.json";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect, useState } from "react";

const NinthSection = () => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    // Example: Trigger animation when user scrolls to a certain position
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setTrigger(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className="mx-auto justify-center items-center  flex flex-col  w-11/12"
    >
      <div className="container mx-[17px] w-[358px] pb-10  lg:pb-44  h-[220px] lg:h-[840px] lg:w-[1120px] flex justify-between items-center lg:gap-[22px] gap-[5px]">
        <div
          // variants={fadeIn("up", "tween", 0.3, 1)}
          className="flex-grow"
        >
          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706017235/Screen1_j2zlhn.svg"
            alt="screen"
            title="screen"
            className="w-full h-full"
          />
        </div>

        <div className="relative flex-grow">
          <div
            // variants={fadeIn("up", "tween", 0.3, 1)}
            className="flex-grow"
          >
            <LazyLoadImage
              src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706017268/Screen2_gk6ueu.svg"
              alt="screen"
              title="screen"
              className="w-full h-full"
            />
          </div>

          {/* lottie globe */}
          <div className="absolute left-1/2 transform -translate-x-1/2 bottom-[2.2rem] lg:bottom-[-3.5rem] w-[130px] h-[121px] lg:w-[500px] lg:h-[580px]">
            <LottieAnimation animationData={globe} trigger={trigger}/>
          </div>
        </div>

        <div
          // variants={fadeIn("up", "tween", 0.3, 1)}
          className="relative flex-grow rounded-2xl overflow-hidden"
        >
          {/* lottie gradient */}
          <div className="h-full w-full rounded-2xl flex-grow absolute top-0 left-0 overflow-hidden block md:hidden">
            <LottieAnimation
              animationData={gradient}
              style={{ height: 220, width: 116 }}
              trigger={trigger}
            />
          </div>

          <div className="hidden md:block rounded-[50px] w-full h-full flex-grow absolute top-0 left-0 overflow-hidden">
            <LottieAnimation
              animationData={gradient}
              style={{ height: 800, width: 400, borderRadius: 50 }}
              trigger={trigger}
            />
          </div>

          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706017291/Screen3_vze11b.svg"
            alt="screen"
            title="screen"
            className="z-40 w-full h-full relative"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default NinthSection;
