
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import TermsAndConditions from "./pages/termsandconditions";
import Faqs from "./pages/faqs";
import ContactUs from "./pages/contactUs";
import Aml from "./pages/aml";
import PrivacyPolicy from "./pages/privacyPolicy";
import InstantConversion from "./pages/instantConversion";
import NotFound from "./pages/notFound";
import AutoConvert from "./pages/autoConvert";
import Account from "./pages/account";
import Redirect from "./components/Redirect";
import { Helmet } from "react-helmet-async";


const RedirectToBlog = () => {
  useEffect(() => {
    window.location.href = 'https://blog.usecrane.co';
  }, []);
  return null;
};

const App = () =>{
  return (
    <Router>
      <div className="App">
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/", 
              "@type": "BreadcrumbList", 
              "itemListElement": [{
                "@type": "ListItem", 
                "position": 1, 
                "name": "Instant Conversion",
                "item": "https://usecrane.co/instant-conversion"  
              },{
                "@type": "ListItem", 
                "position": 2, 
                "name": "Auto Conversion",
                "item": "https://usecrane.co/auto-convert"  
              },{
                "@type": "ListItem", 
                "position": 3, 
                "name": "Crane Blog",
                "item": "https://blog.usecrane.co/"  
              },{
                "@type": "ListItem", 
                "position": 4, 
                "name": "FAQs",
                "item": "https://usecrane.co/faqs"  
              },{
                "@type": "ListItem", 
                "position": 5, 
                "name": "Account",
                "item": "https://usecrane.co/account"  
              }]
            }
          `}
        </script>
      </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog/how-to-convert-crypto-or-bitcoin-to-cash" element={<Redirect to="https://blog.usecrane.co/how-to-convert-crypto-or-btc-to-naira-on-crane/ " />} />
          <Route path="/blog/top-5-high-paying-freelance-skills-and-jobs-to-learn-in-nigeria-in-2024" element={<Redirect to="https://blog.usecrane.co/top-5-high-paying-freelance-skills-and-jobs-to-learn-in-nigeria-in-2024/ " />} />
          <Route path="/blog/how-do-i-delete-my-crane-account" element={<Redirect to="https://blog.usecrane.co/how-do-i-delete-my-crane-account/" />} />
          <Route path="/blog/how-do-i-withdraw-money-from-crane-to-my-bank-account" element={<Redirect to="https://blog.usecrane.co/how-do-i-withdraw-money-from-crane-to-my-bank-account/ " />} />
          <Route path="/post/?id=8625309&title=How+do+I+sign+up+for+a+Crane+account?" element={<Redirect to="https://blog.usecrane.co/how-to-create-an-account-on-crane-in-3-easy-steps " />} />
          <Route path="/blog/how-to-track-btc-sent-to-your-crane-wallet" element={<Redirect to="https://blog.usecrane.co/how-to-track-bitcoin-btc-sent-to-my-crane-wallet/ " />} />
          <Route path="/blog/how-to-convert-crypto-or-bitcoin-to-cash-on-crane" element={<Redirect to="https://blog.usecrane.co/how-to-convert-crypto-or-btc-to-naira-on-crane/" />} />
          <Route path="/blog/how-to-navigate-bitcoin-deposits-on-crane" element={<Redirect to="https://blog.usecrane.co/introducing-the-bep20-network-for-seamless-bitcoin-transactions-on-crane/ " />} />
          <Route path="/blog/how-do-i-sign-up-for-a-crane-account" element={<Redirect to="https://blog.usecrane.co/how-to-create-an-account-on-crane-in-3-easy-steps/" />} />
          <Route path="/blog/what-are-the-different-usdt-networks-you-should-know" element={<Redirect to="https://blog.usecrane.co/usdt-vs-usdc-which-stablecoin-is-best-for-cross-border-payment/" />} />
          <Route path="/blog/why-is-my-crypto-transaction-pending-on-crane" element={<Redirect to="https://blog.usecrane.co/why-is-my-crypto-transaction-pending-on-crane/" />} />
          <Route path="/blog/what-are-crane-coins" element={<Redirect to="https://blog.usecrane.co/what-are-crane-coins-cnc/" />} />
          <Route path="/blog/top-10-most-promising-cryptocurrencies-for-2024" element={<Redirect to="https://blog.usecrane.co/the-best-cryptocurrencies-for-investment/" />} />
          <Route path="/blog/how-do-i-buy-data-on-crane" element={<Redirect to="https://blog.usecrane.co/how-do-i-buy-data-on-crane/" />} />
          <Route path="/blog/usdc-vs-usdt:-what's-the-difference-between-these-stablecoins" element={<Redirect to="https://blog.usecrane.co/usdt-vs-usdc-which-stablecoin-is-best-for-cross-border-payment/" />} />
          <Route path="/blog/how-do-i-buy-airtime-on-crane" element={<Redirect to="https://blog.usecrane.co/how-do-i-buy-airtime-on-crane/" />} />
          <Route path="/blog/how-do-i-receive-cryptocurrency-on-crane" element={<Redirect to="https://blog.usecrane.co/how-do-i-receive-cryptocurrency-on-crane/" />} />
          <Route path="/blog/what-are-the-top-most-currently-asked-questions-about-crypto" element={<Redirect to="https://blog.usecrane.co/crypto-101-top-40-terms-you-should-know/" />} />
          <Route path="/post/?id=8587643&title=How+to+track+BTC+sent+to+your+Crane+Wallet" element={<Redirect to="https://blog.usecrane.co/how-to-track-bitcoin-btc-sent-to-my-crane-wallet/" />} />
          <Route path="/post/?id=8548477&title=Finding+your+way+around+the+Crane+App" element={<Redirect to="https://blog.usecrane.co/finding-your-way-around-the-crane-app/" />} />
          <Route path="/post/?id=8804710&title=Navigating++Bitcoin+Deposits+on+Crane" element={<Redirect to="https://blog.usecrane.co/introducing-the-bep20-network-for-seamless-bitcoin-transactions-on-crane/" />} />
          <Route path="/blog/top-10-most-promising-cryptocurrencies-for-2024" element={<Redirect to="https://blog.usecrane.co/the-best-cryptocurrencies-for-investment/" />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/" element={<ContactUs />} />
          <Route path="/aml" element={<Aml />} />
          <Route path="/instant-conversion" element={<InstantConversion />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/auto-convert" element={<AutoConvert />} />
          <Route path="/blog" element={<RedirectToBlog/>}/>
          <Route path="/account" element={<Account/>}/>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;


