export const cardsData = [
  {
    id: 1,
    imgUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016568/InstallIcon_z6otvt.svg",
    title: "  Install the Crane app",
    subTitle:
      "  Download and install the Crane App from your App store or Google Play Store",
  },
  {
    id: 2,
    imgUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706013699/AccountIcon_gmc1vd.svg",
    title: "  Set-up your free account",
    subTitle:
      " Fill out your account information and complete your KYC verification.",
  },
  {
    id: 3,
    imgUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706017406/TradeIcon_q3e0tt.svg",
    title: " Start receiving and selling",
    subTitle:
      "Be a part of diverse financial activities: receive, sell, buy airtime & data.",
  },
];

export const fourCardsData = [
  {
    id: 1,
    imgUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016921/Pattern.1_g3hvbm.svg",
    iconUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014423/Card_Illustrations_xfw3ro.svg",
    title: "Fast Conversion",
    subTitle:
      "No long process, no middleman. Convert your crypto to cash the fastest way possible. Your funds are deposited directly into your bank account within minutes.",
  },
  {
    id: 2,
    imgUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016955/Pattern.2_l0nsyg.svg",
    iconUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014343/Card_Illustrations_1_tnkcvz.svg",
    title: "Competitive Market Rates",
    subTitle:
      "We provide the best market rates. Our pricing model is simple and transparent, with no hidden fees or charges. Nobody really does it better!",
  },
  {
    id: 3,
    imgUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706017015/Pattern.4_qup14b.svg",
    iconUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016123/Icons-Security_gdrxyu.svg",
    title: "End-to-end Security",
    subTitle:
      "We understand the importance of protecting your digital assets,that’s why all Crane accounts are encrypted with the AES-256 encryption standard.",
  },
  {
    id: 4,
    imgUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016987/Pattern.3_hosolx.svg",
    iconUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014388/Card_Illustrations_2_xrulhn.svg",
    title: "Global Remittance",
    subTitle:
      "Receive money from *literally* anywhere in the world with your payment link. - Whether it is your employer, client, friend or family, you can request payments from any part of the world with your link. Senders don’t even need to sign-up.",
  },
];

export const socials = [
  {
    id: 1,
    name: "twitter",
    imageUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706017443/Twitter_a2xrow.svg",
    url: "https://twitter.com/usecrane_?s=11&t=UNNchmHAHeWryFVvjmD9rg",
    arialabel: "visit our twitter",
  },
  {
    id: 2,
    name: "linkedin",
    imageUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016609/LinkedIn_slq2vx.svg",
    url: "https://www.linkedin.com/company/crane-incorporation",
    arialabel: "visit our linkedin",
  },
  {
    id: 3,
    name: "instagram",
    imageUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016530/Instagram_eqvkx7.svg",
    url: "https://instagram.com/usecrane_?igshid=MzRlODBiNWFlZA==",
    arialabel: "visit our instagram",
  },
  {
    id: 4,
    name: "facebook",
    imageUrl:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706015086/Facebook_vgdzcx.svg",
    url: "https://web.facebook.com/craneincorporation",
    arialabel: "visit our facebook",
  },
];

export const otherPages = [
  {
    id: 1,
    page: "FAQs",
    url: "/faqs",
  },
  {
    id: 2,
    page: "Blog",
    url: "https://blog.usecrane.co/",
  },
  {
    id: 3,
    page: "Contact Us",
    url: "mailto:support@usecrane.co",
  },
  {
    id: 4,
    page: "Privacy Policy",
    url: "/privacy-policy",
  },
  {
    id: 5,
    page: "Terms & Conditions",
    url: "/termsandconditions",
  },
  {
    id: 6,
    page: "AML",
    url: "/aml",
  },
  {
    id: 6,
    page: "Account",
    url: "/account",
  },
];

export const allFaqs = [
  {
    title: "What does Crane do?",
    content: [
      [
        "Crane helps freelancers, businesses, and (pretty much everyone) to receive payments from anywhere in cryptocurrency and convert them to their local currency, like the Nigerian Naira (NGN).",
      ],
    ],
  },
  {
    title: "Who is Crane built for?",
    content: [
      [
        "Crane is built for freelancers, independent contractors, businesses, and anyone who wants to receive payments in crypto from clients outside Nigeria. We help you collect payments in crypto and convert them to naira in five minutes or less. ",
      ],
    ],
  },
  {
    title: "What’s Crane Coins (CNC))? ",
    content: [
      [
        `Crane Coins (CNC) are the rewards for completing activities, including tasks and transactions, on Crane. 
        You'll also receive CNC from referral activities. It's a utility token you can earn and spend on Crane 
        or Crane-supported channels only. Learn more about how to earn and spend CNC <a class="text-white" href="https://blog.usecrane.co/what-are-crane-coins-cnc/" target="_blank" rel="noopener noreferrer">here</a>.`,
      ],
    ],
  },
  {
    title: " Can I send crypto on Crane?",
    content: [
      [
        "No, you can NOT send or buy crypto on Crane. We’re an off-ramp service; therefore, you can only receive crypto on our platform. Other services we provide include converting crypto to local currencies (e.g., naira) and bill settlements (e.g., airtime recharge and data subscription) using Crane Coins (CNC). ",
      ],
    ],
  },
  {
    title: "Which cryptocurrency do you support for receiving payments?",
    content: [
      [
        "Currently, you can receive payment in six (6) cryptocurrencies, namely Binance (BNB), Bitcoin (BTC), Dogecoin (DOGE), Ethereum (ETH), Tether (USDT), USD Coin (USDC), and Tron (TRX).",
      ],
    ],
  },
  {
    title: " Which countries do you support for payment withdrawals? ",
    content: [
      [
        "Currently, we only support payment withdrawals in Nigeria. This means you can only convert crypto to naira on the Crane app. However, our operations will expand to Ghana and Kenya shortly, letting you convert crypto to Ghana cedis and Kenya shillings. ",
      ],
    ],
  },
  {
    title: " Can I set up automatic crypto conversion on Crane?",
    content: [
      [
        `Yes, you can. The Auto-convert (previously “auto-sell”) feature lets you automatically convert digital 
        assets to naira once they reach a predefined value. It helps you stay ahead of the 
        volatile cryptocurrency market. Click <a class="text-white" href="https://blog.usecrane.co/finding-your-way-around-the-crane-app/" target="_blank" rel="noopener noreferrer">here</a> to learn how to set up auto-convert on Crane.`,
      ],
    ],
  },
  {
    title: "Is Crane compliant with regulations?",
    content: [
      [
        "Our platform complies with the relevant financial regulations, including KYC and anti-money laundering laws (AML), as stipulated by the Central Bank of Nigeria and other controlling bodies. We also have a robust audit process to regularly review our compliance status.",
      ],
    ],
  },
  {
    title:
      "What happens if I forget my password and lose access to my account?",
    content: [
      ["Follow the steps below to reset your password on Crane."],
      [
        "1.  Click on the “Forgot password” button on the sign-in page on Crane app.",
      ],
      [
        "2.  Enter your Crane registered email to receive a six-digit one-time password (OTP).",
      ],
      ["3.  Enter the OTP on the Crane app."],
      [
        "4.  Provide a new password. For account security, we recommend your password be a 16-digit character filled with lower and upper cases, numbers, and symbols. ",
      ],
    ],
  },
  {
    title: "Can I cancel a cash withdrawal request after initiation?",
    content: [
      [
        "Once a withdrawal request is initiated, it enters the processing queue and cannot be cancelled or modified. Ensure you carefully review all details before confirming a withdrawal.",
      ],
    ],
  },
  {
    title: "How long does a withdrawal request to local bank accounts take?",
    content: [
      [
        "Payment withdrawals to local bank accounts are nearly instant on Crane. Contact us at support@usecrane.co if you experience unusual withdrawal delays.",
      ],
    ],
  },
  {
    title: "Is there a minimum or maximum withdrawal limit on Crane?",
    content: [
      [
        `Yes, there are withdrawal limits on Crane. The restrictions depend on the level of Know Your Customer (KYC) you’ve completed. Click <a class="text-white" href="https://blog.usecrane.co/how-to-create-an-account-on-crane-in-3-easy-steps/" target="_blank" rel="noopener noreferrer">here</a> to learn more about the requirements for the KYC tiers on Crane.`,
      ],
      [
        "However, irrespective of the KYC tier you belong to, no limits exist for receiving payments in crypto into your Crane wallet.",
      ],
    ],
  },
  {
    title: "Are your fees transparent?",
    content: [
      [
        "Yes, our fees are transparent and affordable. Receiving payment in cryptocurrency on Crane is FREE, while we only charge 2% of the transaction amount for converting crypto to naira.",
      ],
    ],
  },
  {
    title: "Do you hold digital assets during the conversion process?",
    content: [
      [
        "Yes, we do. We provide a customized wallet for storing digital currencies on our platform.",
      ],
    ],
  },
  {
    title: "How long does it take to convert crypto to cash on Crane?",
    content: [
      ["It takes 100 seconds or less to convert crypto to cash on Crane."],
    ],
  },
];

export const getStarted = [
  {
    title: "What does Crane do?",
    content: [
      [
        "Crane helps freelancers, businesses, and (pretty much everyone) to receive payments from anywhere in cryptocurrency and convert them to their local currency, like the Nigerian Naira (NGN).",
      ],
    ],
  },
  {
    title: "Who is Crane built for?",
    content: [
      [
        "Crane is built for freelancers, independent contractors, businesses, and anyone who wants to receive payments in crypto from clients outside Nigeria. We help you collect payments in crypto and convert them to naira in five minutes or less. ",
      ],
    ],
  },
  {
    title: "What’s Crane Coins (CNC))? ",
    content: [
      [
        `Crane Coins (CNC) are the rewards for completing activities, including tasks and transactions, on Crane. 
        You'll also receive CNC from referral activities. It's a utility token you can earn and spend on Crane 
        or Crane-supported channels only. Learn more about how to earn and spend CNC <a class="text-white" href="https://blog.usecrane.co/what-are-crane-coins-cnc/" target="_blank" rel="noopener noreferrer">here</a>.`,
      ],
    ],
  },
  {
    title: " Can I send crypto on Crane?",
    content: [
      [
        "No, you can NOT send or buy crypto on Crane. We’re an off-ramp service; therefore, you can only receive crypto on our platform. Other services we provide include converting crypto to local currencies (e.g., naira) and bill settlements (e.g., airtime recharge and data subscription) using Crane Coins (CNC). ",
      ],
    ],
  },
  {
    title: "Which cryptocurrency do you support for receiving payments?",
    content: [
      [
        "Currently, you can receive payment in six (6) cryptocurrencies, namely Binance (BNB), Bitcoin (BTC), Dogecoin (DOGE), Ethereum (ETH), Tether (USDT), USD Coin (USDC), and Tron (TRX).",
      ],
    ],
  },
  {
    title: " Which countries do you support for payment withdrawals? ",
    content: [
      [
        "Currently, we only support payment withdrawals in Nigeria. This means you can only convert crypto to naira on the Crane app. However, our operations will expand to Ghana and Kenya shortly, letting you convert crypto to Ghana cedis and Kenya shillings. ",
      ],
    ],
  },
  {
    title: " Can I set up automatic crypto conversion on Crane?",
    content: [
      [
        `Yes, you can. The Auto-convert (previously “auto-sell”) feature lets you automatically convert digital 
        assets to naira once they reach a predefined value. It helps you stay ahead of the 
        volatile cryptocurrency market. Click <a class="text-white" href="https://blog.usecrane.co/finding-your-way-around-the-crane-app/" target="_blank" rel="noopener noreferrer">here</a> to learn how to set up auto-convert on Crane.`,
      ],
    ],
  },
  {
    title: "Is Crane compliant with regulations?",
    content: [
      [
        "Our platform complies with the relevant financial regulations, including KYC and anti-money laundering laws (AML), as stipulated by the Central Bank of Nigeria and other controlling bodies. We also have a robust audit process to regularly review our compliance status.",
      ],
    ],
  },
  {
    title:
      "What happens if I forget my password and lose access to my account?",
    content: [
      ["Follow the steps below to reset your password on Crane."],
      [
        "1.  Click on the “Forgot password” button on the sign-in page on Crane app.",
      ],
      [
        "2.  Enter your Crane registered email to receive a six-digit one-time password (OTP).",
      ],
      ["3.  Enter the OTP on the Crane app."],
      [
        "4.  Provide a new password. For account security, we recommend your password be a 16-digit character filled with lower and upper cases, numbers, and symbols. ",
      ],
    ],
  },
];

export const accountManagement = [
  {
    title: "Can I cancel a cash withdrawal request after initiation?",
    content: [
      [
        "Once a withdrawal request is initiated, it enters the processing queue and cannot be cancelled or modified. Ensure you carefully review all details before confirming a withdrawal.",
      ],
    ],
  },
  {
    title: "How long does a withdrawal request to local bank accounts take?",
    content: [
      [
        "Payment withdrawals to local bank accounts are nearly instant on Crane. Contact us at support@usecrane.co if you experience unusual withdrawal delays.",
      ],
    ],
  },
  {
    title: "Is there a minimum or maximum withdrawal limit on Crane?",
    content: [
      [
        `Yes, there are withdrawal limits on Crane. The restrictions depend on the level of Know Your Customer (KYC) you’ve completed. Click <a class="text-white" href="https://blog.usecrane.co/how-to-create-an-account-on-crane-in-3-easy-steps/" target="_blank" rel="noopener noreferrer">here</a> to learn more about the requirements for the KYC tiers on Crane.`,
      ],
      [
        "However, irrespective of the KYC tier you belong to, no limits exist for receiving payments in crypto into your Crane wallet.",
      ],
    ],
  },
  {
    title: "Are your fees transparent?",
    content: [
      [
        "Yes, our fees are transparent and affordable. Receiving payment in cryptocurrency on Crane is FREE, while we only charge 2% of the transaction amount for converting crypto to naira.",
      ],
    ],
  },
  {
    title: "Do you hold digital assets during the conversion process?",
    content: [
      [
        "Yes, we do. We provide a customized wallet for storing digital currencies on our platform.",
      ],
    ],
  },
  {
    title: "How long does it take to convert crypto to cash on Crane?",
    content: [
      ["It takes 100 seconds or less to convert crypto to cash on Crane."],
    ],
  },
];

export const countryDropdownData = [
  {
    id: 1,
    icon: "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1674584940/Euro_Icon_40px_1_xfvc81.svg",
    name: "Euros",
  },
  {
    id: 2,
    icon: "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1674584940/Group_a8ogny.svg",
    name: "Pounds",
  },
  {
    id: 3,
    icon: "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1674584940/Nigerian_Naira_Icon_40px_1_wyjtyo.svg",
    name: "Naira",
  },
  {
    id: 4,
    icon: "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1674584940/US_Dollars_Icon_40px_wepmbf.svg",
    name: "Dollars",
  },
];

export const submenulinks = [
  {
    image:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708542413/Instant_Convert_2x_t1emhm.jpg",
    mobileimage:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708606759/Instant_Convert_rxhnem.svg",

    title: "Instant conversion",
    text: "Skip the middleman, Crane puts cash directly in your bank account from your crypto holdings",
    url: "/instant-conversion",
  },
  {
    image:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708542439/Auto-convert_2x_vfzxs7.jpg",
    mobileimage:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708606764/Auto-convert_womuik.svg",

    title: "Auto-conversion",
    text: "Set your crypto to auto-convert when it hits a specific market value.",
    url: "/auto-convert",
  },
  {
    image:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708542428/Crypto_Alerts_2x_wbnohi.jpg",
    mobileimage:
      "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708606782/Crypto_Alerts_ttnsw7.svg",

    title: "Crypto alerts",
    text: "Stay informed and make informed trading decisions. Know when your crypto hits your target.",
    url: "/",
  },
];
