

import Feature from "../Feature";
import Slider from "../slider/Slider";

const UseCaseSection = () => {
  return (
    <div className=" w-full xl:w-11/12 lg:py-[140px] pt-[60px] pb-[40px] mx-auto flex flex-col items-center justify-center text-center">
      <Feature title="" subTitle="WHO CAN USE US?" longText=""  maxWidth="606px" whiteSpace="nowrap" />
      {/* <div className="bg-gray-200 h-screen flex items-center"> */}
      <Slider />
    {/* </div> */}
    </div>
  );
};

export default UseCaseSection;
