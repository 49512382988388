
import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";

const LottieAnimation = ({ animationData, style, trigger }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (trigger) {
      setIsVisible(true);
    }
  }, [trigger]);

  return (
    isVisible && (
      <Lottie
      animationData={animationData}
      loop={true}
      autoplay={true}
      style={style}
      rendererSettings={{
        preserveAspectRatio: "xMidYMid slice",
      }}
    />
    )
  );
};

export default LottieAnimation;
